import React from "react";
import { Link } from "gatsby";
import {
  IoIosArrowForward,
  IoIosDesktop,
  IoIosArchive,
  IoIosDisc,
} from "react-icons/io";

import Image from "../components/image";
import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { PageHeader, CallToAction } from "../components/site/";

import TimeEntryVideo from "../images/timesheets/TimeEntry_Preview.mp4";
import PayHeroPlusXero from "../images/logos/PayHero/PayHeroPlusXero.svg";

const Timesheets = (props) => (
  <Layout>
    <Seo
      title="Online Timesheets, Time Tracking & Approvals | PayHero"
      description="Powerful online time tracking makes it easy for you and your employees to record timesheets and stay on top of hours worked so your payroll is always accurate."
      pathname={props.location.pathname}
    />
    <PageHeader
      feature="Time Tracking"
      title={[
        <span key={0} className="-fontPrimary">
          Online time tracking{" "}
        </span>,
        "to help your business",
      ]}
      description="When you use PayHero, your team’s hours, leave and holidays are automatically included in your payroll. No manual entry. No worries!"
      imagePath="PageHeader_Time.png"
    />
    <Container>
      <Wrapper>
        <Row stackGap={40} className="-textCenter">
          <Box size={33}>
            <h3>
              <IoIosDesktop />
              <br />
              Go paperless
            </h3>
            <p>
              Say goodbye to paper timesheets. Employees can record time on any
              device and it will automatically sync with your{" "}
              <Link to="/payroll">payroll</Link>.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoIosArchive />
              <br />
              Recordkeeping compliance
            </h3>
            <p>
              Automatically keep a secure online record of the hours worked and
              breaks taken by employees each day.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoIosDisc />
              <br />
              Accurate payroll
            </h3>
            <p>
              Ditch the data entry and avoid costly payroll mistakes by
              generating your payroll directly from online timesheets.
            </p>
          </Box>
        </Row>
        <hr />
        <Row stackGap={80} alignCenter mobileReverse>
          <Box size={50}>
            <h2>Easy timesheet approval</h2>
            <h4>
              Make it easy for remote payroll staff by having your onsite
              managers approve timesheets before they’re included in a pay.
              Approving employee time adds an extra layer of accountability and
              management capability for PayHero users.
            </h4>
          </Box>
          <Box size={50}>
            <Image
              alt="Payroll Mobile App | PayHero"
              filename="TimesheetApprovalPayHero.png"
              addShadow
              rounded
            />
          </Box>
        </Row>
        <Row stackGap={80} alignCenter mobileReverse>
          <Box size={50}>
            <video
              autoPlay
              loop
              muted
              playsInline
              css={{ maxWidth: "661px", width: "100%" }}
            >
              <source src={TimeEntryVideo} type="video/mp4" />
            </video>
          </Box>
          <Box size={50}>
            <h2>The easy way to pay hourly staff</h2>
            <h4>
              Don’t waste time chasing up paper timesheets and typing all those
              hours into your payroll system. Hourly employees can enter hours
              straight into PayHero online or from their phone. Those hours flow
              into your payroll, increasing accuracy and letting you focus on
              the things that matter.
            </h4>
          </Box>
        </Row>
        <Row stackGap={80} alignCenter>
          <Box size={50} stackGap={40}>
            <div>
              <h2>Timesheets on the go</h2>
              <h4>
                Get employees to submit their hours there and then, rather than
                trying to recall what work they did at the end of the day or
                week. Perfect for companies with remote staff or those who
                regularly move about, the employee mobile app lets staff record
                their hours and break times from anywhere.
              </h4>
            </div>
            <Link className="link-arrow" to="/mobile">
              Discover Mobile Timesheets <IoIosArrowForward />
            </Link>
          </Box>
          <Box size={50}>
            <Image
              alt="Payroll Mobile App | PayHero"
              filename="PayHero_EmpMobile.png"
              maxWidth={500}
              centerImage
              addShadow
            />
          </Box>
        </Row>
        <Row stackGap={80} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              alt="GPS Time Tracking | PayHero"
              filename="GPSTimeTracking_PayHero.png"
              addShadow
              rounded
            />
          </Box>
          <Box size={50}>
            <h2>GPS time tracking</h2>
            <h4>
              PayHero's GPS time tracking makes it easy to manage your remote or
              mobile workforce. Turn on geolocation for accurate timesheets with
              increased employee accountability. Your staff can start a timer on
              their phone, which pinpoints the location of where they are when
              they clock in and out.
            </h4>
          </Box>
        </Row>
        <Row stackGap={80} alignCenter>
          <Box size={50} stackGap={40}>
            <div>
              <h2>Fast Admin Time Entry</h2>
              <h4>
                Still using paper timesheets? No problem. It’s quick and easy
                for Admins to enter time into PayHero as well. View a week at a
                time and enter the total number of hours or days worked by each
                employee or enter bulk time entries for multiple employees
                working the same shift.
              </h4>
            </div>
          </Box>
          <Box size={50}>
            <Image
              alt="Bulk Timesheet Entry | PayHero"
              filename="BulkTimeEntry_PayHero.png"
            />
          </Box>
        </Row>
        <Row stackGap={80} alignCenter mobileReverse>
          <Box size={50}>
            <img
              className="-center"
              src={PayHeroPlusXero}
              alt="PayHero + Xero Integration"
              height="120"
            />
          </Box>
          <Box size={50}>
            <h2>Track your labour costs</h2>
            <h4>
              To really understand how your business ticks, record employee time
              by business function, department or the work they’re doing. Split
              your wages into separate accounts or cost centres for detailed
              cost analysis and reporting.{" "}
              <Link to="/xero">Integrate with Xero</Link> to automatically
              apportion those costs in your accounting software.
            </h4>
          </Box>
        </Row>
        <Row stackGap={80} alignCenter>
          <Box size={50} stackGap={40}>
            <div>
              <h2>To-the-minute timesheets</h2>
              <h4>
                Get your employees to clock in and out with a selfie to record
                exact start and end times. Monitor attendance and paid and
                unpaid breaks, eliminate buddy punching and make it fun! It’s
                the fastest and most accurate way to pay shift-based employees.
              </h4>
            </div>
            <Link className="link-arrow" to="/timeclock">
              Discover Photo Time Clock <IoIosArrowForward />
            </Link>
          </Box>
          <Box size={50}>
            <Image
              alt="Photo Time Clock | PayHero"
              filename="PayHeroShift_ClockInEntry.png"
              addShadow
              centerImage
              rounded
              maxWidth={500}
            />
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <CallToAction />
  </Layout>
);

export default Timesheets;
